<template>
    <div class="flex flex-column">
        <div class="flex pb-3">
            <RadioButton v-model="marketingChoiceValue" inputId="marketingChoice1" name="optin" value="optin" @change="$emit('update:MarketingOptions', marketingChoiceValue.value)" />
            <label for="marketingChoice1" class="ml-2 marketing-preferences">
                Never miss a thing, click here to receive the latest financial news and insights from the clarity team.
            </label>
        </div>
        <div class="flex pb-3">
            <RadioButton v-model="marketingChoiceValue" inputId="marketingChoice2" name="optout" value="optout" @change="$emit('update:MarketingOptions', marketingChoiceValue.value)" />
            <label for="marketingChoice2" class="ml-2 marketing-preferences">
                Click here to opt out of receiving communications from the clarity team.<br />
                <span class="font-italic">Please note that as part of your service, we may be required to contact you with essential service specific information.</span>
            </label>
        </div>
    </div>
</template>

<script>

import {computed} from "vue";

export default {
  name: "MarketingOptions",
  props: {
      modelValue: String
  },
  setup(props, context) {

    const marketingChoiceValue = computed({
      get() {
          if(props.modelValue) {
              return props.modelValue
          }else{
              return 'none'
          }
      },
      set(value) {
          context.emit('update:modelValue', value)
      }
    })

    return {
        marketingChoiceValue
    }
  }
}
</script>

<style scoped>
.marketing-preferences {
    color: var(--clarity-dark-grey);
    text-align: left;
    font: normal normal normal 18px Open Sans;
}
.font-italic {
    font: normal italic normal 14px Open Sans;
}


:deep(.p-radiobutton .p-radiobutton-box .p-radiobutton-icon) {
    background-color: var(--clarity-blue) !important;
}
:deep(.p-radiobutton .p-radiobutton-box.p-highlight, .p-radiobutton .p-radiobutton-box.p-highlight:hover) {
    border-color: var(--clarity-blue) !important;
    background-color: var(--clarity-pure-white) !important;
}

:deep(.p-radiobutton .p-radiobutton-box, .p-radiobutton .p-radiobutton-box:hover){
    border-color: var(--clarity-dark-grey) !important;
}
</style>
