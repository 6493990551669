<template>
  <div class="flex-column">
    <div class="flex justify-content-between">
      <div class="flex-column half">
        <div class="dropdown-label">Strategic Advice Service</div>
        <Dropdown class="full-width" v-model="advice" :options="adviceOptions" optionLabel="label" optionValue="label" :disabled="true" />
      </div>
      <div class="flex-column half">
        <div class="dropdown-label">Investment Management Service</div>
        <Dropdown class="full-width" v-model="ims" :options="imsOptions" optionLabel="label" optionValue="label" :disabled="true" />
      </div>
    </div>
  </div>
</template>

<script>
import {computed, ref, watch} from "vue";
import {useStore} from "vuex";

export default {
  name: "Services",
  setup() {
    const store = useStore()
    const services = computed(() => store.getters.getClientServices)

    const advice = ref(services.value.advice ?? '')
    const ims = ref(services.value.investment ?? '')
    watch(services,(val) => {
      advice.value = val.advice
      ims.value = val.investment
    })

    const imsOptions = ref([
      {
        'label': 'Portfolio',
      },
      {
        'label': 'Portfolio+',
      },
      {
        'label': 'Special',
      }
    ])

    const adviceOptions = ref([
      {
        'label': 'OTO',
      },
      {
        'label': 'OTO+',
      },
      {
        'label': 'Staff',
      }
    ])

    return {
      services,
      imsOptions,
      advice,
      ims,
      adviceOptions
    }
  }
}
</script>

<style scoped>
.dropdown-label {
  font-weight: bold;
  margin: 20px 0px;
}
.half {
  width: 48%;
}
span {
  display: inline-block;
  white-space: nowrap;
}
.strong {
  font-weight: bold;
}
.italicised {
  font-style: italic;
}
</style>
