<template>
  <div class="flex-column full-width">
    <MarketingOptions class="clarity-margin-top-m" v-model="marketingChoice" />
    <div class="flex justify-content-end" style="padding-top: 30px;">
      <submit-button @click="updateMarketing" :loading="loadingState" button-text="Save Changes" ></submit-button>
    </div>
  </div>
</template>

<script>
import {computed, ref, watch} from 'vue'
import {useStore} from "vuex";
import SubmitButton from "../common/layout/interactive/SubmitButton";
import MarketingOptions from "@/components/settings/MarketingOptions";


export default {
  name: "Marketing",
  components: {SubmitButton, MarketingOptions},
  setup() {
    const store = useStore()

    const marketingData = computed(() => {
        console.log('options data is ', store.getters.getMarketingChoice);
        return store.getters.getMarketingChoice
    })

    const marketingChoice = ref((marketingData.value) ? marketingData.value : null)

    watch(marketingData,(val) => {
        if(val) {
            marketingChoice.value = val;
        }
    })

    const loadingState = computed(() => store.getters.isLoading('personal-marketing'))

    const updateMarketing = () => {
      store.dispatch('setLoading', {id: 'personal-marketing', state: true})
      store.dispatch('updateSettings', {
        type: 'personal',
        loading: 'personal-marketing',
        title: 'Contact & Marketing Options',
        payload: {
          marketing: {
            marketingChoice: marketingChoice.value
          }
        }
      })
    }

    return {
      marketingChoice,
      updateMarketing,
      loadingState
    }
  }
}
</script>

<style scoped>
.half {
  width: 50%;
}
span {
  display: inline-block;
  white-space: nowrap;
}
.strong {
  font-weight: bold;
}
.italicised {
  font-style: italic;
}
</style>
