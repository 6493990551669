<template>
  <div class="flex-column">
    <div class="flex justify-content-between">
      <div class="flex-column half">
        <div class="dropdown-label">Country of Residence</div>
        <Dropdown class="full-width" v-model="residence" :options="countryOptions" optionLabel="description" optionValue="id"  />
      </div>
      <div class="flex-column half">
        <div class="dropdown-label">Country of Domicile</div>
        <Dropdown class="full-width" v-model="domicile" :options="countryOptions" optionLabel="description" optionValue="id" />
      </div>
    </div>
    <div class="flex justify-content-end" style="padding-top: 30px;">
      <submit-button @click="updateDomicileStatus" :loading="loadingState" button-text="Save Changes" ></submit-button>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import SubmitButton from "../common/layout/interactive/SubmitButton";

export default {
  name: "Domicile",
  components: {SubmitButton},
  setup() {
    const store = useStore()
    const countryOptions = ref([])
    const initialDomicile = computed(() => store.getters.getDomicile)
    const initialResidence = computed(() => store.getters.getResidence)
    const domicile = ref('')
    const residence = ref('')
    watch(initialDomicile,(val) => {
        if(val) {
            domicile.value = val.toString();
        }
    })
    watch(initialResidence,(val) => {
        if(val) {
            residence.value = val.toString();
        }
    })


    const loadingState = computed(() => store.getters.isLoading('personal-domicile'))

    const updateDomicileStatus = () => {
      store.dispatch('setLoading', {id: 'personal-domicile', state: true})
      store.dispatch('updateSettings', {
        type: 'personal',
        loading: 'personal-domicile',
        title: 'Domicile Preferences',
        payload :{
          "countryOfResidence": residence.value,
          "countryOfDomicile": domicile.value,
        }
      })
    }

    const getCountryOptions = async () => {
      const opts = await store.dispatch('getCountryOptions')
      if (opts) {
        countryOptions.value = opts
        if(initialDomicile.value) {
            domicile.value = initialDomicile.value.toString();
        }
        if(initialResidence.value) {
            residence.value = initialResidence.value.toString();
        }
      }
    }

    onMounted(() => {
      getCountryOptions()
    })

    return {
      countryOptions,
      domicile,
      residence,
      updateDomicileStatus,
      loadingState
    }
  }
}
</script>

<style scoped>
.dropdown-label {
  font-weight: bold;
  margin: 20px 0px;
}
.half {
  width: 48%;
}
</style>
