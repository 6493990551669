<template>
  <content-card-full>
    <template #title>
      <card-title>
        <template #title><h2 class="title">Personal Settings</h2></template>
        <template #content>
          <div @click.stop="toggleAll" style="cursor: pointer;">
            <div class="flex align-items-center grey" v-if="all"><span
                class="material-icons-round grey">remove</span>Collapse All
            </div>
            <div class="flex align-items-center grey" v-if="!all"><span
                class="material-icons-round grey">add</span>Expand All
            </div>
          </div>
        </template>
      </card-title>
    </template>
    <template #content-full>
      <div class="flex flex-column">
        <div><h3 class="title mb-3">Introduction</h3></div>
        <div class="flex justify-content-between">
          <div class="flex" style="font-size: 18px; width: 85%;">The ‘Personal Details’ area allows you to update your registration details including your email address and username, group association including checkpoint reporting, domicile preferences, view your clarity service levels and updates your contact and marketing options.
          </div>
          <!--
          <Button class="clarity-btn clarity-gradient-grey dark-grey" style="width: max-content; padding: 15px 45px;">
            <span class="material-icons-round mr-3">history</span>Recent Updates
          </Button>
          -->
        </div>
      </div>

      <spacer-h/>

      <inner-card class="card">
        <template #title>
          <div class="flex justify-content-between">
            <div class="flex"><h3 class="title">registration details</h3></div>
            <div v-if="expanded.registration">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('registration')"><span
                  class="material-icons-round grey">expand_less</span>Hide Details
              </div>
            </div>
            <div v-if="!expanded.registration">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('registration')"><span
                  class="material-icons-round grey">expand_more</span>Show Details
              </div>
            </div>
          </div>
        </template>
        <template #content>
          <div class="flex" style="font-size: 18px;">
            <div>
              Updating your email address on your profile will also update the email address that clarity contacts you on. If you update your name, this will update on your clarity profile and mailings, but we may require additional documentation from you to update this with platform providers.
            </div>
          </div>
          <div v-if="expanded.registration">
            <Registration />
          </div>
        </template>
      </inner-card>

      <inner-card class="card">
        <template #title>
          <div class="flex justify-content-between">
            <div class="flex"><h3 class="title">group association</h3></div>
            <div v-if="expanded.group">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('group')"><span
                  class="material-icons-round grey">expand_less</span>Hide Details
              </div>
            </div>
            <div v-if="!expanded.group">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('group')"><span
                  class="material-icons-round grey">expand_more</span>Show Details
              </div>
            </div>
          </div>
        </template>
        <template #content>
          <div class="flex" style="font-size: 18px;">
            <div>
              The group you are associated with is the group that introduced you to clarity. Members of accountancy groups will also have access to the independence checkpoint reporting section.
            </div>
          </div>
          <div v-if="expanded.group">
            <Group />
          </div>
        </template>
      </inner-card>

      <inner-card class="card">
        <template #title>
          <div class="flex justify-content-between">
            <div class="flex"><h3 class="title">domicile preferences</h3></div>
            <div v-if="expanded.domicile">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('domicile')"><span
                  class="material-icons-round grey">expand_less</span>Hide Details
              </div>
            </div>
            <div v-if="!expanded.domicile">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('domicile')"><span
                  class="material-icons-round grey">expand_more</span>Show Details
              </div>
            </div>
          </div>
        </template>
        <template #content>
          <div class="flex" style="font-size: 18px;">
            <div>
              Your change in domicile may alter the investment products you are able to invest into. We may also require additional documentation to confirm this with platform providers.
            </div>
          </div>
          <div v-if="expanded.domicile">
            <Domicile />
          </div>
        </template>
      </inner-card>

      <inner-card class="card">
        <template #title>
          <div class="flex justify-content-between">
            <div class="flex"><h3 class="title">clarity services</h3></div>
            <div v-if="expanded.services">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('services')"><span
                  class="material-icons-round grey">expand_less</span>Hide Details
              </div>
            </div>
            <div v-if="!expanded.services">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('services')"><span
                  class="material-icons-round grey">expand_more</span>Show Details
              </div>
            </div>
          </div>
        </template>
        <template #content>
          <div class="flex" style="font-size: 18px;">
            <div>
              Your active services are confirmed below. If you would like to discuss your service options or change your services then please talk to your usual adviser or contact the office on <b>0800 3687511</b>.
            </div>
          </div>
          <div v-if="expanded.services">
            <Services />
          </div>
        </template>
      </inner-card>

      <inner-card class="card">
        <template #title>
          <div class="flex justify-content-between">
            <div class="flex"><h3 class="title">Marketing Preferences</h3></div>
            <div v-if="expanded.marketing">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('marketing')"><span
                  class="material-icons-round grey">expand_less</span>Hide Details
              </div>
            </div>
            <div v-if="!expanded.marketing">
              <div class="flex align-items-center grey cursor-pointer" @click.stop="toggleSingle('marketing')"><span
                  class="material-icons-round grey">expand_more</span>Show Details
              </div>
            </div>
          </div>
        </template>
        <template #content>
          <div class="flex" style="font-size: 18px;">
            <div>
              You can update your Contact & Marketing preferences using the inputs below. Please note that it can take up to <b>2 working days</b> for changes to these options to take effect.
            </div>
          </div>
          <div v-if="expanded.marketing">
            <Marketing />
          </div>
        </template>
      </inner-card>

    </template>
  </content-card-full>
</template>

<script>
import ContentCardFull from '@/components/common/ContentCardFull'
import CardTitle from '@/components/common/CardTitle'
import InnerCard from '../common/InnerCard.vue'

import {computed, reactive, ref} from 'vue';
import SpacerH from "@/components/common/layout/SpacerH";
import Registration from "@/components/settings/Registration";
import Group from "@/components/settings/Group";
import Domicile from "@/components/settings/Domicile";
import Services from "@/components/settings/Services";
import Marketing from "@/components/settings/Marketing";
import {useStore} from "vuex";

export default {
  name: "TabPersonalSettings",
  components: {
    Marketing,
    Services,
    Domicile,
    Group,
    Registration,
    SpacerH,
    ContentCardFull,
    CardTitle,
    InnerCard
  },
  setup() {
    const store = useStore()
    const all = ref(true);
    const expanded = reactive({
      registration: true,
      group: true,
      domicile: true,
      services: true,
      marketing: true
    })

    const toggleAll = () => {
      all.value = !all.value
      for (let k in expanded) {
        expanded[k] = all.value
      }
    }

    const toggleSingle = (card) => {
      expanded[card] = !expanded[card]
    }

    return {
      all,
      expanded,
      toggleAll,
      toggleSingle,
      group: computed(() => store.getters.getGroup)
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  box-shadow: unset;
  margin-bottom: 30px;

  .title {
    margin-bottom: 20px;
  }
}

.card:deep(.card-title) {
  padding: 30px;
}

.card:deep(.content) {
  padding: 0px;
  font-size: 18px;
}

.collapsed {
  width: 90%
}
</style>
