<template>
  <div class="flex-column">
    <div class="flex-column half">
      <div class="dropdown-label">Company</div>
      <Dropdown class="full-width" v-model="selectedGroup" :options="groupOptions" optionLabel="name"
                optionValue="name" />
    </div>

    <div v-if="selectedGroup === 'PwC'">
      <h3 class="title mb-3 mt-6">Independence checkpoint reporting</h3>
      <div class="flex justify-content-between">
        <div class="flex" style="font-size: 18px; width: 85%;">
          As you are affiliated with the PwC group, an automated system is available to you for reporting your
          transactions.
          To use this system, select "Enabled" below, and we will email the Independence Checkpoint Support Service with
          the required information when you make any relevant changes to your Online Portfolio.
        </div>
      </div>
      <div class="flex-column half">
        <div class="dropdown-label">Checkpoint Status</div>
        <Dropdown class="full-width" :model-value="checkpointEnabled" :options="enabledOptions" optionLabel="label"
                  optionValue="value" @change="updateCheckpointStatus"/>
      </div>

      <div class="dropdown-label">Individuals included in your Independence Checkpoint Reporting Status</div>
      <div>
        Use the buttons below to update the inclusion of individuals in your reporting. It is important to click the
        'Save
        Changes"
        button at the bottom of this section after you have made any updates to ensure that they are saved.
      </div>

      <DataTable :value="checkpointUsers" responsiveLayout="scroll" class="rd-table mt-6">
        <!--      <Column field="username" header="Username" />-->
        <!--      <Column field="email" header="Email Address" />-->
        <!--      <Column field="first" header="First Name" />-->
        <!--      <Column field="last" header="Surname" />-->

        <Column field="name" header="Name"/>

        <Column header="Actions">
          <template #body="slotProps">
            <Button v-if="slotProps.data.enabled" class="clarity-btn clarity-gradient-orange"
                    @click="toggleUserCheckpoint(slotProps.data.uuid)">
              <span class="material-icons-round white mr-3">check_circle</span>Included
            </Button>
            <Button v-else class="clarity-btn clarity-gradient-grey dark-grey"
                    @click="toggleUserCheckpoint(slotProps.data.uuid)" :disabled="!checkpointEnabled">Include in
              Reporting
            </Button>
          </template>
        </Column>
      </DataTable>
    </div>

    <div class="flex justify-content-end" style="padding-top: 30px;">
      <submit-button @click="updateGroup" :loading="loadingState" button-text="Save Changes" ></submit-button>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import SubmitButton from "../common/layout/interactive/SubmitButton";

export default {
  name: "Group",
  components: {SubmitButton},
  setup() {
    const store = useStore()

    const group = computed(() => store.getters.getGroup)
    const selectedGroup = ref(group.value ? group.value.name : '')
    watch(group,(val) => {
      selectedGroup.value = val.name;
    })
    const groupOptions = ref([])

    const checkpoint = computed(() => store.getters.getCheckpointEnabled)
    const checkpointEnabled = ref(checkpoint.value ?? '')
    watch(checkpoint,(val) => {
        checkpointEnabled.value = val
    })
    const enabledOptions = ref([
      {
        'label': 'Enabled',
        'value': true
      },
      {
        'label': 'Disabled',
        'value': false
      }
    ])

    const updateCheckpointStatus = (status) => {
      checkpointEnabled.value = status.value
      if (status.value === false) {
        checkpointUsers.value.forEach(user => {
          user.enabled = false
        })
      }
    }

    const additionalCheckpointUsers = computed(() => store.getters.getCheckpointAdditional)
    const checkpointUsers = ref(additionalCheckpointUsers.value ? additionalCheckpointUsers.value : [])
    watch(additionalCheckpointUsers,(val) => {
        checkpointUsers.value = val
    })

    const toggleUserCheckpoint = (add) => {
      checkpointUsers.value.forEach(user => {
        if (user.uuid === add) {
          user.enabled = !user.enabled
        }
      })
    }

    const loadingState = computed(() => store.getters.isLoading('personal-group'))

    const updateGroup = () => {
      store.dispatch('setLoading', {id: 'personal-group', state: true})
      store.dispatch('updateSettings', {
        type: 'personal',
        loading: 'personal-group',
        title: 'Group Association',
        payload: {
          "checkpoint": {
            "enabledForClient": checkpointEnabled.value,
            "othersIncluded": checkpointUsers.value
          },
          "group": groupOptions.value.find(opt => opt.name === selectedGroup.value)
        }
      })
    }

    const getGroupOptions = async () => {
      const opts = await store.dispatch('getGroupOptions')
      if (opts) {
        groupOptions.value = opts
      }
    }

    onMounted(() => {
      getGroupOptions()
    })

    return {
      checkpointUsers,
      enabledOptions,
      checkpointEnabled,
      groupOptions,
      selectedGroup,
      toggleUserCheckpoint,
      updateCheckpointStatus,
      updateGroup,
      loadingState
    }
  }
}
</script>

<style scoped>
.dropdown-label {
  font-weight: bold;
  margin: 20px 0px;
}

.half {
  width: 50%;
}

.clarity-btn {
  padding: 15px 45px;
}
</style>
