<template>
  <div class="flex-column">
    <div class="flex justify-content-between">
      <div class="flex-column half">
        <div class="dropdown-label">Username:</div>
        <InputText type="text" placeholder="Enter Username..." class="p-inputtext-lg w-full"
                   v-model="user.username" :disabled="true" />
      </div>
      <div class="flex-column half">
        <div class="dropdown-label">Email:</div>
        <InputText type="text" placeholder="Enter Email..." class="p-inputtext-lg w-full"
                   v-model="user.email" :disabled="true" />
      </div>
    </div>
    <div class="flex justify-content-between">
      <div class="flex-column half">
        <div class="dropdown-label">First Name:</div>
        <InputText type="text" placeholder="Enter First Name..." class="p-inputtext-lg w-full"
                   v-model="user.first" />
      </div>
      <div class="flex-column half">
        <div class="dropdown-label">Surname:</div>
        <InputText type="text" placeholder="Enter Surname..." class="p-inputtext-lg w-full"
                   v-model="user.last" />
      </div>
    </div>
    <div class="flex justify-content-end" style="padding-top: 30px;">
      <submit-button @click="registerUser" :loading="loadingState" button-text="Save Changes" ></submit-button>
    </div>
  </div>
</template>

<script>
import {computed, reactive, watch} from "vue";
import {useStore} from "vuex";
import SubmitButton from "../common/layout/interactive/SubmitButton";

export default {
  name: "Registration",
  components: {SubmitButton},
  setup() {
    const store = useStore()
    const user = reactive({
      username: store.getters.getUsername ?? '',
      email: store.getters.getEmail ?? '',
      first: store.getters.getFirstName ?? '',
      last: store.getters.getSurname ?? ''
    })

    const theUsername = computed(() => {
      return store.getters.getUsername;
    })
    watch(theUsername,(val) => {
      user.username = val;
    })
    const theEmail = computed(() => {
      return store.getters.getEmail;
    })
    watch(theEmail,(val) => {
      user.email = val;
    })
    const theFirst = computed(() => {
      return store.getters.getFirstName
    })
    watch(theFirst,(val) => {
      user.first = val;
    })
    const theLast = computed(() => {
      return store.getters.getSurname;
    })
    watch(theLast,(val) => {
      user.last = val;
    })

    const loadingState = computed(() => store.getters.isLoading('personal-registration'))

    const registerUser = async () => {
      await store.dispatch('setLoading', {id: 'personal-registration', state: true})
      await store.dispatch('updateSettings', {type: 'personal', loading: 'personal-registration', title: 'Registration Details', payload: {firstname: user.first, surname: user.last}})
      // then we reload the people as names will have updated.
      await store.commit('setPeopleLoading', true)
      await store.dispatch('getPeopleOptions')
    }

    return {
      user: computed(() => user),
      loadingState,
      registerUser
    }
  }
}
</script>

<style scoped>
.dropdown-label {
  font-weight: bold;
  margin: 20px 0px;
}
.half {
  width: 48%;
}
</style>
